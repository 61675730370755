import { render, staticRenderFns } from "./selectUsers.vue?vue&type=template&id=12ff05b9&scoped=true"
import script from "./selectUsers.vue?vue&type=script&lang=js"
export * from "./selectUsers.vue?vue&type=script&lang=js"
import style0 from "./expand.scss?vue&type=style&index=0&id=12ff05b9&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ff05b9",
  null
  
)

export default component.exports